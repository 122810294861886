import React, { FunctionComponent, useState, useRef } from 'react';
import styled from 'styled-components';

import { UploadIcon } from '../atoms/icons/Upload'
import { AddIcon } from '../atoms/icons/Add'
import { DownloadIcon } from '../atoms/icons/Download'

const FloatingActionButton = styled.button<{isVisible: boolean, delay: string}>`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background: #fff;
    border: none;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);

    transition: transform ${props => props.delay} ease-out, max-height ${props => props.delay} ease-in;
    transform: ${props => props.isVisible ? 'scale(1)' : 'scale(0)'};
    max-height: ${props => props.isVisible ? '100px' : '0px'};
`;



const SpeedDialButton = styled(FloatingActionButton)<{isVisible: boolean}>`
    height: 72px;
    width: 72px;
    border-radius: 36px;

    transition: transform 0.2s ease-in;
    transform: ${props => props.isVisible ? 'rotate(45deg)' : 'rotate(0deg)'};

    max-height: 100vh;
`;



const SpeedDialMenu = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`;

const ButtonLabel = styled.div`
    padding: 2px;
    border-radius: 5px;
    background-color: #0005;
    color: white;
    transform-origin: right 50%;
    position:absolute;
    width: 80px;
    transform: translate(-100px, 2px);
`

type HandleFileUploadFunction = (files: File[]) => void

const FileInputFAB : FunctionComponent<{isVisible: boolean, delay: string, handleFileUpload: HandleFileUploadFunction}> = ({isVisible, delay, handleFileUpload}) => {
    const fileUploaderRef = useRef<HTMLInputElement>(null);

    const forwardClick = () => {
        if (fileUploaderRef?.current) {
            fileUploaderRef.current.click();
        }
    }

    return <FloatingActionButton isVisible={isVisible} delay={'0.1s'} onClick={forwardClick} >
        <ButtonLabel>upload</ButtonLabel>
        <UploadIcon/>
        <input type="file" ref={fileUploaderRef} style={{
            display: 'block',
            visibility: 'hidden',
            width: 0,
            height: 0
        }} onChange={async (e) => {
            const { files } = e.target as HTMLInputElement;
            if (!files) {
                return;
            }
            handleFileUpload(Array.from(files));
        }}/>
    </FloatingActionButton>
}

const DownloadFAB : FunctionComponent<{isVisible: boolean, delay: string, getSvgDownloadURL: () => string}> = ({isVisible, delay, getSvgDownloadURL}) => {
    const aRef = useRef<HTMLAnchorElement>(null);

    const handleDownload = () => {
        const downloadURL = getSvgDownloadURL();
        if (aRef.current && downloadURL) {
            aRef.current.href = downloadURL;
            aRef.current.click();
        }
        // URL.revokeObjectURL(url);
    }

    return <FloatingActionButton isVisible={isVisible} delay={'0.1s'} onClick={() => handleDownload()}>
        <ButtonLabel>download</ButtonLabel>
        <a ref={aRef} download="my_svg.svg"/>
        <DownloadIcon/>
    </FloatingActionButton>
}

export const SpeedDial : FunctionComponent<{handleFileUpload: (files: File[]) => void, getSvgDownloadURL: () => string}> = ({handleFileUpload, getSvgDownloadURL}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <SpeedDialMenu onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
            <SpeedDialButton isVisible={isOpen} delay={'0s'} onTouchStart={() => setIsOpen(!isOpen)}><AddIcon/></SpeedDialButton>
            <FileInputFAB isVisible={isOpen} delay={'0.1s'} handleFileUpload={handleFileUpload}/>
            <DownloadFAB isVisible={isOpen} delay={'0.2s'} getSvgDownloadURL={getSvgDownloadURL}></DownloadFAB>
        </SpeedDialMenu>
    );
}