import { isValidColor } from '../color/parse';
import { toHexColor } from '../color/conversion';
import { colorAttributes } from '../color/constants';
import { normalizeCss, getColorMapFromCss, replaceColorInCss } from '../css/parse';

export function normalizeColors(document: Document) : Document {
    // Set height and width to 100%
    const svgNode = document.querySelector("svg");
    if (svgNode) {
        svgNode.setAttribute("width", "100%");
        svgNode.setAttribute("height", "100%");
    }

    // find and normalize css in all style tags
    const styleNodes = document.querySelectorAll("style").forEach((element: any) => {
        element.innerHTML = normalizeCss(element.innerHTML);
    });

    // TODO: split the following into 2 functions
    // change all color attributes to hex
    // persist initial color as data attribute
    const nodes = document.querySelectorAll("*");
    nodes.forEach((element: any) => {
        colorAttributes.forEach( attr => {
            if (element.attributes[attr]) {
                const color = element.attributes[attr].value;
                const hexColor = toHexColor(color);
                if (hexColor) {
                    element.setAttribute("color-node", "");
                    element.setAttribute(`initial-${attr}`, hexColor);
                    element.setAttribute(attr, hexColor);
                }
            }
        })
    });
    return document;
}

export function replaceColor(document: Document, initialColor: string, newColor: string) {
    // find and replace color in all style tags
    const styleNodes = document.querySelectorAll("style").forEach((element: any) => {
        element.innerHTML = replaceColorInCss(element.innerHTML, initialColor, newColor);
    });

    const nodes = document.querySelectorAll("*[color-node]");
    nodes.forEach((element: any) => {
        const attributes = colorAttributes.filter(attr => element.getAttribute(`initial-${attr}`) === initialColor);
        for(const attr of attributes) {
            element.setAttribute(attr, newColor);
        }
    });
}

export function getColorMap(document: Document) : Map<string, string> {
    
    // getColorMapFromCss
    const styleTagColorMaps = Array.from(document.querySelectorAll("style")).map((el: any) => getColorMapFromCss(el.innerHTML));
    // get colors from attributes
    const colorMap = new Map<string, string>();
    const nodes = document.querySelectorAll("*[color-node]");
    nodes.forEach((element: any) => {
        const availableAttributes = colorAttributes.filter(attr => element.getAttribute(attr) && element.getAttribute(`initial-${attr}`))
        for(const attr of availableAttributes) {
            const initialColor = element.attributes[`initial-${attr}`].value;
            const currentColor = element.attributes[attr].value;
            colorMap.set(initialColor, currentColor);
        }
    });
    return mergeMaps([...styleTagColorMaps, colorMap]);
}

function mergeMaps<S,T>(maps: Map<S,T>[]) : Map<S,T> {
    return new Map<S,T>(...maps.map(m => Array.from(m)));
}


export function parseDocument(svg: string) : Document {
  const parser = new DOMParser();
  const document = parser.parseFromString(svg, "image/svg+xml");
  return document;
}

export function serializeDocument(svg: Document) : string {
  var s = new XMLSerializer();
  return s.serializeToString(svg);
}