import React, { FunctionComponent, useState } from 'react';

import { parseDocument, serializeDocument, replaceColor, getColorMap, normalizeColors } from '../../svg/document/svg';
import { SplitScreen } from '../layouts/SplitScreen';
import { Logo } from '../atoms/Logo';
import { H2 } from '../atoms/Text';
import { FlexFooter } from '../atoms/FlexFooter';
import { SvgViewer } from '../molecules/SvgViewer';
import { SideBar } from '../molecules/SideBar';
import { AppBar } from '../molecules/AppBar';
import { ColorControls } from '../organisms/ColorControls';
import { SpeedDial } from '../organisms/SpeedDial';

const testString = '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0 0 30 30"><desc>Example SVG file</desc><rect x="10" y="10" width="10" height="10" fill="#ff0000"/><rect x="20" y="10" width="5" height="5" fill="#80ff00"/><rect x="10" y="20" width="5" height="5" fill="#007bff"/><rect x="25" y="10" width="5" height="5" fill="#007bff"/><rect x="5" y="10" width="5" height="5" fill="#ff00ff"/><rect x="5" y="15" width="5" height="5" fill="#00ffff"/></svg>';
const testString2 = '<?xml version="1.0"?><svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" viewBox="0 0 30 30"><desc>Example SVG file</desc><rect x="10" y="10" width="10" height="10" fill="blue"/></svg>';

type AppProps = {}

function readSvg(file: File[]) : Promise<Document> {
  return file[0].text().then(parseDocument)
}

const App: FunctionComponent<AppProps>  = () => {
    const [svg, setSvg] = useState<Document | undefined>(undefined);
    const [colorMap, setColorMap] = useState(new Map<string, string>())

    const replaceColor = (colorToReplace: string, color: string) => {
      const newColorMap = new Map(colorMap);
      newColorMap.set(colorToReplace, color);
      setColorMap(newColorMap);
    }

    const getSvgDownloadURL = () : string => {
      if (!svg) {
        return ''
      }
      const svgString = serializeDocument(svg);
      const blob = new Blob([svgString], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      return url;
    }

    return (
      <SplitScreen>
        <SvgViewer svg={svg} colorMap={colorMap}/>
        <SideBar>
          <AppBar><Logo/><H2>Vector Palette</H2></AppBar>
          <ColorControls colorMap={colorMap} replaceColor={replaceColor}/>
        </SideBar>
        <SpeedDial getSvgDownloadURL={getSvgDownloadURL} handleFileUpload={async (f) => {
          const newSvg = await readSvg(f);
          setSvg(normalizeColors(newSvg));
          setColorMap(getColorMap(normalizeColors(newSvg)));
          }}
          />
      </SplitScreen>
    );
}

export default App;

