import React, { FunctionComponent } from 'react';

export const UploadIcon : FunctionComponent<{}> = () => {
    return (
        <svg height="19px" version="1.1" viewBox="0 0 14 19" width="14px" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g fill="#000000" id="Core" transform="translate(-425.000000, -213.000000)">
                    <g id="file-upload" transform="translate(425.000000, 213.500000)">
                        <path d="M4,13 L10,13 L10,7 L14,7 L7,0 L0,7 L4,7 L4,13 Z" id="Shape"/>
                        <rect height="2" id="Rectangle-path" width="14" x="0" y="15"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}