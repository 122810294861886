import React, { FunctionComponent } from 'react';

export const AddIcon : FunctionComponent<{}> = () => {
    return (
        <svg height="14px" version="1.1" viewBox="0 0 14 14" width="14px" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g fill="#000000" id="Core" transform="translate(-131.000000, -5.000000)">
                    <g id="add" transform="translate(131.000000, 5.000000)">
                        <path d="M14,8 L8,8 L8,14 L6,14 L6,8 L0,8 L0,6 L6,6 L6,0 L8,0 L8,6 L14,6 L14,8 L14,8 Z" id="Shape"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}