
import { rgbaPattern, shortHexPattern, hexPattern, rgbPattern } from './regex';
import { namedColors } from './namedColors';
import { RGBAColor, RGBColor, HexColor, Color } from './color';

type ColorParser = (color: string) => Color | undefined

export function isValidColor(color: string) : boolean {
    const parsers : ColorParser[] = [
        parseNamedColor,
        parseShortHex,
        parseHex,
        parseRGB,
        parseRGBA
    ];
    return parsers.some((parse: ColorParser) => !!parse(color));
}

export function parseColor(color: string) : Color | undefined {
    const parsers : ColorParser[] = [
        parseNamedColor,
        parseShortHex,
        parseHex,
        parseRGB,
        parseRGBA
    ];
    for(const parse of parsers){
        const parsedColor = parse(color);
        if (parsedColor) {
            return parsedColor;
        }
    }
    return;
}

function parseNamedColor(color: string) : Color | undefined {
    const hex = namedColors.get(color);
    if (hex) {
        return new HexColor(hex);
    }
    return;
}

function parseShortHex(color: string) : Color | undefined {
    if (color.match(shortHexPattern)) {
        const [r,g,b] = Array.from(color.slice(1));
        return new HexColor('#'+r+r+b+b+g+g);
    }
    return;
}

function parseHex(color: string) : Color | undefined {
    if (color.match(hexPattern)) {
        return new HexColor(color)
    }
    return;
}

function parseRGB(color: string) : Color | undefined {
    const rgbValues = color.match(rgbPattern)?.slice(1);
    if (!rgbValues) {
        return undefined;
    }
    if (rgbValues.every(parseRGBNumber)) {
        const [r,g,b] = rgbValues.map((v) => parseInt(v));
        return new RGBColor(r,g,b);
    } else if (rgbValues.every(isPercentage)) {
        const [r,g,b] = rgbValues.map((v) => parseInt(v.slice(0, -1)));
        return new RGBColor(r,g,b, true);
    }
    return undefined;
}

function parseRGBA(color: string) : Color | undefined {
    const groups = color.match(rgbaPattern);
    if (!groups) {
        return undefined;
    }
    const rgbValues = groups.slice(1,4);
    const alphaValue = groups[4];

    const rgbAsNumbers = rgbValues.every(parseRGBNumber);
    const rgbAsPercent = rgbValues.every(isPercentage);
    const isRGBUnitConsistent = rgbAsNumbers || rgbAsPercent;

    if (isRGBUnitConsistent && isAlphaValue(alphaValue)) {
        const cleanRGB = rgbAsNumbers ? (v: string) => parseInt(v) : (v: string) => parseInt(v.slice(0, -1));
        const [r,g,b] = rgbValues.map(cleanRGB);
        const a = isPercentage(alphaValue) ? parseInt(alphaValue.slice(0,-1)) : parseInt(alphaValue);
        return new RGBAColor(r,g,b,a, rgbAsPercent, isPercentage(alphaValue))
    }
    return;
}

function parseRGBNumber(value: string) : boolean {
    if (!value.match(/^[0-9]{1,3}$/g)) {
        return false
    }
    const number = parseInt(value, 10);
    return 0 <= number && number <= 255;
}

function isAlphaValue(value: string) : boolean {
    return isPercentage(value) || inUnitInterval(value);
}

function isPercentage(value: string) : boolean {
    const isPercentage = value[value.length-1] === '%'
    const number = parseInt(value.slice(0, -1), 10);
    if (isPercentage && 0 <= number && number <= 100) {
        return true;
    }
    return false;
}

function inUnitInterval(value: string) : boolean {
    const number = parseFloat(value);
    return 0 <= number && number <= 1;
}