import React from 'react';
import styled from 'styled-components';

export const H2 = styled.h2`
    margin: 0;
    color: #595959;
    font-family: 'Prompt', sans-serif;
    font-size: 1.2em;
`;

