import React, {FunctionComponent} from "react"

export const Logo: FunctionComponent<{}>  = () => {
  // const s = `<svg
  //   view-box="0 0 10 10"
  //   height="10"
  //   width="10"
  //   xmlns="http://www.w3.org/2000/svg"
  // >
  //   <rect x="0" y="0" width="5" height="5" fill="cyan" />
  //   <rect x="5" y="0" width="5" height="5" fill="black" />
  //   <rect x="5" y="5" width="5" height="5" fill="magenta" />
  //   <rect x="0" y="5" width="5" height="5" fill="yellow" />
  // </svg>`;
  // const dataUrl = `data:image/svg+xml;base64,${window.btoa(s)}`;

  return <svg
    viewBox="0 0 10 10"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0" y="0" width="5" height="5" fill="cyan" />
    <rect x="5" y="0" width="5" height="5" fill="black" />
    <rect x="5" y="5" width="5" height="5" fill="magenta" />
    <rect x="0" y="5" width="5" height="5" fill="yellow" />
  </svg>;
}

