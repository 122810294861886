import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type SplitScreenProps = {}

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 800px) {
        flex-direction: row-reverse;
    }
    height: 100vh;
    width: 100vw;
`;

const Primary = styled.div`
    width: 100vw;
    height: 50vh;
    @media (min-width: 800px) {
        height: 100vh;
    }
    position: relative;
`;

const Secondary = styled.div`
    width: 100vw;
    height: 50vh;
    @media (min-width: 800px) {
        max-width: 250px;
        height: 100vh;
    }
`;



export const SplitScreen: FunctionComponent<SplitScreenProps>  = ({children}) => {
    const [childA, childB, childC] = React.Children.toArray(children);
    
    return <Layout>
        <Primary>{ childA }</Primary>
        <Secondary>{ childB }</Secondary>
        {childC}
    </Layout>
}