export interface Color {
    toHex: () => string
}

export class HexColor implements Color {
    private value: string;

    constructor(color: string) {
        this.value = color;
    }

    toHex(): string {
        return this.value
    }
}

export class RGBColor implements Color {
    private r:number;
    private g:number;
    private b:number;

    constructor(r: number, g: number, b:number, rgbAsPercent=false) {
        this.r = (rgbAsPercent) ? Math.round(r*2.55) : r;
        this.g = (rgbAsPercent) ? Math.round(g*2.55) : g;
        this.b = (rgbAsPercent) ? Math.round(b*2.55) : b;
    }

    toHex(): string {
        const rHex = this.r.toString(16).padStart(2, '0');
        const gHex = this.g.toString(16).padStart(2, '0');
        const bHex = this.b.toString(16).padStart(2, '0');
        return "#"+rHex+gHex+bHex;
    }
}

export class RGBAColor implements Color {
    private r:number;
    private g:number;
    private b:number;
    private a:number;

    constructor(r: number, g: number, b:number, a:number, rgbAsPercent=false, alphaAsPercent=false) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }

    toHex(): string {
        const rHex = this.r.toString(16).padStart(2, '0');
        const gHex = this.g.toString(16).padStart(2, '0');
        const bHex = this.b.toString(16).padStart(2, '0');
        return "#"+rHex+gHex+bHex;
    }
}