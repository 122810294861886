import React, { FunctionComponent } from 'react';

export const DownloadIcon : FunctionComponent<{}> = () => {
    return (
        <svg height="19px" version="1.1" viewBox="0 0 14 19" width="14px" xmlns="http://www.w3.org/2000/svg" >
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g fill="#000000" id="Core" transform="translate(-383.000000, -213.000000)">
                    <g id="file-download" transform="translate(383.000000, 213.500000)">
                        <path d="M14,6 L10,6 L10,0 L4,0 L4,6 L0,6 L7,13 L14,6 L14,6 Z M0,15 L0,17 L14,17 L14,15 L0,15 L0,15 Z" id="Shape"/>
                    </g>
                </g>
            </g>
        </svg>
    );
}