import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { ColorMapper } from '../molecules/ColorMapper'

type ControlsProps = {
    colorMap: Map<string, string>
    replaceColor(colorToReplace: string, color: string): void
}

const Controls = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

export const ColorControls: FunctionComponent<ControlsProps>  = ({colorMap, replaceColor}) => {

    const colorMappers = Array.from(colorMap.entries()).map(([initialColor, currentColor]) => {
        return <ColorMapper
            key={initialColor}
            initialColor={initialColor}
            currentColor={currentColor}
            setCurrentColor={replaceColor}
            />
    })

    return (
        <Controls>
            {colorMappers}
        </Controls>
    );
}