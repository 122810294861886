import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

type ColorMapperProps = {
    initialColor: string,
    currentColor: string,
    setCurrentColor: (a: string, b:string) => void
}

export const ColorMapper: FunctionComponent<ColorMapperProps> = ({initialColor, currentColor, setCurrentColor}) => {
    return <StyledMapper>
        <ColorInput type='color' value={initialColor} disabled />
        <Arrow />
        <ColorInput type='color' value={currentColor} onChange={(e) => setCurrentColor(initialColor, e.target.value)}/>
    </StyledMapper>
}

const StyledMapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5% 5% 0;
`;

const ColorInput = styled.input`
    flex: 1;
    
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    height: 100%;
    max-height: 60px;
    max-width: 60px;

    border: 1px solid #eee;
    padding: 0;
    border-radius: 50%;
    background: transparent;

    &:hover, &:active, &:focus {
        background: #eee;
    }

    &::-webkit-color-swatch {
        border: none;
        border-radius: 50%;
    }

    &::-moz-color-swatch {
        border: none;
        border-radius: 50%;
    }

    &::-webkit-color-swatch-wrapper {
        padding: 10%;
    }
`;

const Arrow : FunctionComponent<{}> = () => {
    return <div style={{ flex: 1, maxHeight: '60px', maxWidth: '60px' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
            <defs>
                <clipPath>
                    <path fill="#00f" fillOpacity=".514" d="m-7 1024.36h34v34h-34z"/>
                </clipPath>
                <clipPath>
                    <path fill="#aade87" fillOpacity=".472" d="m-6 1028.36h32v32h-32z"/>
                </clipPath>
            </defs>
            <path d="m345.44 248.29l-194.29 194.28c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744l171.91-171.91-171.91-171.9c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.29 194.28c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373"
                transform="matrix(.03541-.00013.00013.03541 2.98 3.02)"
                fill="#ddd"
                />
        </svg>
    </div>;
}