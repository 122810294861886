// var css = require('css');
import { parse, stringify, Rule, Declaration, Stylesheet } from 'css';

import { ColorMap } from '../color/colorMap';
import { colorAttributes } from '../color/constants';
import { toHexColor } from '../color/conversion';

export function normalizeCss(cssText: string) : string {
    const cssObject = persistInitialColors(normalizeColors(parse(cssText)));
    return removeWhitespace(stringify(cssObject));
}

function normalizeColors(cssObject: Stylesheet) : Stylesheet {
    const rules = cssObject?.stylesheet?.rules || [];
    const newRules: Rule[] = [];
    for (const rule of rules) {
        const declarations = (rule as Rule)?.declarations || [];
        const newDeclarations = declarations.map(d => normalizeColorsInDeclaration(d));
        (rule as Rule).declarations = newDeclarations;
    }
    return cssObject;
}

export function replaceColorInCss(cssText: string, initialColor: string, newColor: string) : string {
    const cssObject = parse(cssText);
    if (cssObject?.stylesheet?.rules) {
        cssObject.stylesheet.rules = cssObject?.stylesheet?.rules.map(r => replaceColorsInRule(r, initialColor, newColor))
    }
    return removeWhitespace(stringify(cssObject));
}

function replaceColorsInRule(rule: Rule, initialColor: string, newColor: string): Rule {
    if (rule.declarations) {
        // TODO: Optimize this
        // get attrs with matching initialColor
        const matchingAttributes = new Set(
            rule.declarations
            .map(d => colorAttributes.filter((a:string): boolean => (d as Declaration).property === `initial-${a}` && (d as Declaration).value === initialColor))
            .flat()
        );
        // set declarations with attrs to newColor
        rule.declarations = rule.declarations.map((d:Declaration) => {
            if (d.property && matchingAttributes.has(d.property)) {
                d.value = newColor;
            }
            return d;
        })
    }
    return rule;
}

function normalizeColorsInDeclaration(d: Declaration): Declaration {
    if (!d.property || !d.value) {
        return d;
    }
    if (colorAttributes.includes(d.property) && toHexColor(d.value)) {
        d.value = toHexColor(d.value);
    }
    return d;
}

function persistInitialColors(cssObject: Stylesheet) : Stylesheet {
    const rules = cssObject?.stylesheet?.rules || [];
    for (const i in rules) {
        const rule = rules[i] as Rule;
        const declarations = rule?.declarations || [];
        const colorDeclarations = selectColorDeclarations(declarations);
        for (const d of colorDeclarations) {
            const newDeclaration = {...d} as Declaration;
            newDeclaration.property = `initial-${newDeclaration?.property}`
            declarations.push(newDeclaration);
        }
    }
    return cssObject;
}

export function getColorMapFromCss(cssText: string) : ColorMap {
    const cssObject = parse(cssText);
    const rules = cssObject?.stylesheet?.rules || [];
    let colorMap = new Map<string, string>();
    for (const i in rules) {
        const rule = rules[i] as Rule;
        const declarations = rule?.declarations || [];
        const colorDeclarations = selectColorDeclarations(declarations);
        const initialColorDeclarations = selectInitialColorDeclarations(declarations);
        const partialColorMap = getColorMapFromDeclarations(colorDeclarations, initialColorDeclarations);
        colorMap = new Map([...Array.from(colorMap), ...Array.from(partialColorMap)])
    }
    return colorMap;
}

function selectColorDeclarations(declarations: Declaration[]) : Declaration[] {
    return declarations.filter((d:Declaration) => colorAttributes.includes(d.property || ''))
}

function selectInitialColorDeclarations(declarations: Declaration[]) : Declaration[] {
    return declarations.filter(
        (d:Declaration) => {
            return colorAttributes.map(a => `initial-${a}`).includes(d.property || '')
        }
    );
}

function getColorMapFromDeclarations(declarations: Declaration[], initDeclarations: Declaration[]) : ColorMap {
    const initAttrToColor = new Map<string, string>(initDeclarations.map((d => [d.property || '', d.value || ''])));
    const attrToColor = new Map<string, string>(declarations.map((d => [d.property || '', d.value || ''])));

    const colorMap = new Map<string, string>();

    for (const attr of Array.from(attrToColor.keys())) {
        const initialColor = initAttrToColor.get(`initial-${attr}`);
        const currentColor = attrToColor.get(attr);
        if (currentColor && initialColor) {
            colorMap.set(initialColor, currentColor);
        }
    }

    return colorMap;
}

function removeWhitespace(s: string) : string {
    return s.replace(/\s/g, '');
}
