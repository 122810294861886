import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { serializeDocument, replaceColor } from '../../svg/document/svg';

type ViewerProps = {
    svg: Document | undefined
    colorMap: Map<string, string>
}

const Viewer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
`;

export const SvgViewer: FunctionComponent<ViewerProps>  = ({svg, colorMap}) => {
    if (!svg) {
        return <Viewer></Viewer>
    }

    Array.from(colorMap).forEach(([colorToReplace, color]) => {
            replaceColor(svg, colorToReplace, color);
    });
    const svgString = serializeDocument(svg);
    return (
        <Viewer>
            <div style={{width: '80%', height: '80%', display: 'flex'}} dangerouslySetInnerHTML={{ __html: svgString }} />
        </Viewer>
    );
}