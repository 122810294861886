import React from 'react';
import styled from 'styled-components';

export const AppBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 10px;
    outline: 1px solid #f0f0f0;
`;